/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Header from "./header/header";
import "./layout.scss";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <footer className="footer-wrapper">
        <div className="footer-row">
          {/* <div className="footer-menu">
            <Link to="/page-2/">Who We Are</Link>
            <Link to="/page-2/">What We Do</Link>
            <Link to="/page-2/">How OWW Works</Link>
          </div> */}
          <div className="social-icons-wrapper">
            <a
              href="mailto:info@onlinewritingworkshop.com"
              className="social-icon"
              target="_blank"
            >
              Email
            </a>
            <a
              href="https://twitter.com/oww_sff"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              Twitter
            </a>
          </div>
          <div className="footer-legal-stuff">
            <Link to="/member-agreement/">Member Agreement</Link>
            <Link to="/privacy-policy/">Privacy Policy</Link>
          </div>
        </div>

        <div className="footer-row second">
          <div className="footer-text">
            © 2000 - {new Date().getFullYear()} Online Writing Workshop.
            Submissions are © individual authors.
          </div>
          <div className="footer-text">
            Website handcrafted by{` `}
            <a href="https://sprillow.com">Sprillow</a>
          </div>
        </div>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
