import React, { useState } from "react";
import { Link } from "gatsby";
import "./header.scss";
import Button from "../button/button";

import Burger from "../burger/burger";

import { LogoImage } from "../image";

import menuIcon from "../../images/burger-menu.svg";

function Header() {
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  return (
    <header className="header">
      {/* burger menu (hidden on larger screens) */}
      <Burger
        openBurgerMenu={openBurgerMenu}
        onClick={() => setOpenBurgerMenu(!openBurgerMenu)}
      />
      <div className="header-wrapper">
        <div
          className={`header-burger-menu-wrapper ${
            openBurgerMenu ? "focused" : ""
          }`}
        >
          <Button
            className="burger-menu-button"
            icon={menuIcon}
            text="Menu"
            onClick={() => setOpenBurgerMenu(!openBurgerMenu)}
          />
        </div>
        <div className="logo">
          <Link to="/">
            <LogoImage />
            <div className="logo-text">OnlineWritingWorkshop.com</div>
          </Link>
        </div>

        <div className="menu-and-action-buttons">
          <ul className="header-menu">
            <li>
              <Link to="/what-we-offer/">What We Offer</Link>
            </li>
            <li>
              <Link to="/how-oww-works/">How OWW Works</Link>
            </li>
            <li>
              <Link to="/who-we-are/">Who We Are</Link>
            </li>
            <li>
              <Link to="/pricing/">Pricing</Link>
            </li>
            <li>
              <a
                href="https://blog.onlinewritingworkshop.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
          </ul>
          <div className="membership-actions">
            <Button
              className="signup-button"
              text="Sign Up"
              fill
              path="/free-trial.shtml"
            ></Button>
            <Button
              className="member-login-button"
              text="Member Login"
              path="/app/ww/ww.cgi?:memb:y"
            ></Button>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
