import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import "./button.scss";

const Button = ({
  color,
  fill,
  semiTransparentFill,
  text,
  path,
  onClick,
  className,
  icon,
  submit,
  disabled,
}) => {
  const buttonImage = <img className="button-icon" src={icon} alt="" />;

  let classNames = ["button", color];
  if (fill) {
    classNames.push("fill");
  }
  if (semiTransparentFill) {
    classNames.push("semi-transparent-fill");
  }
  if (className) {
    classNames.push(className);
  }
  if (!path && !submit) {
    return (
      <div className={classNames.join(" ")} onClick={onClick}>
        {icon && buttonImage}
        {text}
      </div>
    );
  } else if (submit) {
    return (
      <input
        type="submit"
        disabled={disabled}
        className={classNames.join(" ")}
        onClick={onClick}
        value={text}
      ></input>
    );
  } else if (path.includes('#')) {
    return (
      <a href={path} className={classNames.join(" ")} onClick={onClick}>
        {icon && buttonImage}
        {text}
      </a>
    );
  } else {
    return (
      <Link to={path} className={classNames.join(" ")} onClick={onClick}>
        {icon && buttonImage}
        {text}
      </Link>
    );
  }
};

Button.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.bool,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
};

// these will act as defaults if no prop to override is passed
Button.defaultProps = {
  color: "purple",
  fill: false,
  submit: false,
  disabled: false,
};

export default Button;
