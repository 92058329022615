import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const queries = graphql`
  query {
    logo: file(relativePath: { eq: "oww-logo.png" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    banner: file(relativePath: { eq: "oww-banner-patrick-fore.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Image = ({ type }) => {
  const data = useStaticQuery(queries)
  if (type === "logo") {
    return <Img fixed={data.logo.childImageSharp.fixed} />
  } else if (type === "banner") {
    return (
      <Img
        fluid={data.banner.childImageSharp.fluid}
        style={{ height: "100%" }}
        imgStyle={{ objectFit: "cover" }}
      />
    )
  }
}

const LogoImage = () => {
  return <Image type="logo" />
}

const BannerImage = () => {
  return <Image type="banner" />
}

export { LogoImage, BannerImage }
