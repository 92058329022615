import React from "react";
import { bool, func } from "prop-types";
import { Link } from "gatsby";
import { CSSTransition } from "react-transition-group";

import "./burger.scss";

const Burger = ({ openBurgerMenu, setOpenBurgerMenu }) => {
  return (
    <div className="burger-menu-wrapper">
      <CSSTransition
        in={openBurgerMenu}
        timeout={200}
        classNames="burger-menu-animation"
        unmountOnExit
      >
        <div className="burger-menu-list-wrapper">
          <ul className="burger-menu-list">
            <li>
              <Link to="/what-we-offer/">What We Offer</Link>
            </li>
            <li>
              <Link to="/how-oww-works/">How OWW Works</Link>
            </li>
            <li>
              <Link to="/who-we-are/">Who We Are</Link>
            </li>
            <li>
              <Link to="/pricing/">Pricing</Link>
            </li>
            <li>
              <a
                href="https://blog.onlinewritingworkshop.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
            <li className="burger-signup-button">
              <Link to="/free-trial.shtml">Sign Up</Link>
            </li>
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
};
Burger.propTypes = {
  openBurgerMenu: bool.isRequired,
};
export default Burger;
